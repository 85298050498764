import React from 'react';

import Layout from 'sections/Layout';
import LetsTalkFormContainer from 'sections/CustomerStories/LetsTalkFormContainer';
import Video from 'sections/Highlights/Video/';

import 'sections/Highlights/common.scss';

const formData = {
  letsTalkFormMainContentTitle: 'Do you have a project in mind?',
  letsTalkFormMainContentSubtitle: 'Great, we’d like to co-invest!',
};

const seo = {
  opengraphTitle: 'Screver | Highlights',
  canonical: 'https://screver.com/events/screver-ocx/highlights',
  metaDesc: 'Do you have a project in mind? Great, we’d like to co-invest! ',
  metaKeywords: '',
  opengraphDescription:
    'Do you have a project in mind? Great, we’d like to co-invest! ',
  opengraphImage: {
    srcSet:
      'https://wp.screver.com/wp-content/uploads/2021/09/IMG-General-300x156.png 300w, https://wp.screver.com/wp-content/uploads/2021/09/IMG-General.png 678w',
    sourceUrl:
      'https://wp.screver.com/wp-content/uploads/2021/09/IMG-General.png',
  },
  opengraphSiteName: 'Screver',
  opengraphUrl: 'https://screver.com/events/screver-ocx/highlights',
  schema: {
    raw:
      '{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://screver.com/#website","url":"https://screver.com/","name":"Screver","description":"Screver website","potentialAction":[{"@type":"SearchAction","target":{"@type":"EntryPoint","urlTemplate":"https://screver.com/?s={search_term_string}"},"query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"ImageObject","@id":"https://screver.com/events/screver-ocx/highlights#primaryimage","inLanguage":"en-US","url":"/static/ab96a95f9be22f324d6485fd8603ffe9/IMG-General.png","contentUrl":"/static/ab96a95f9be22f324d6485fd8603ffe9/IMG-General.png","width":678,"height":352},{"@type":"WebPage","@id":"https://screver.com/events/screver-ocx/highlights#webpage","url":"https://screver.com/events/screver-ocx/highlights","name":"Screver | Highlights","isPartOf":{"@id":"https://screver.com/#website"},"datePublished":"2021-08-12T15:19:37+00:00","dateModified":"2021-11-19T07:26:51+00:00","description":"Do you have a project in mind? Great, we’d like to co-invest! ","breadcrumb":{"@id":"https://screver.com/events/screver-ocx/highlights#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://screver.com/events/screver-ocx/highlights"]}]},{"@type":"BreadcrumbList","@id":"https://screver.com/events/screver-ocx/highlights#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"name":"Highlights"}]}]}',
    pageType: ['WebPage'],
    articleType: [],
  },
  title: 'Screver | Highlights',
  twitterDescription:
    'Do you have a project in mind? Great, we’d like to co-invest! ',
  twitterImage: {
    srcSet:
      'https://wp.screver.com/wp-content/uploads/2021/09/IMG-General-300x156.png 300w, https://wp.screver.com/wp-content/uploads/2021/09/IMG-General.png 678w',
    sourceUrl:
      'https://wp.screver.com/wp-content/uploads/2021/09/IMG-General.png',
    uri: '/home/img-general/',
    title: 'IMG – General',
  },
  twitterTitle: 'Screver | Highlights',
  readingTime: 0,
  opengraphType: 'article',
  opengraphPublishedTime: '',
  opengraphModifiedTime: '2021-11-19T07:26:51+00:00',
  opengraphPublisher: '',
};

const Highlights = () => {
  return (
    <Layout
      seo={seo}
      headerDarkColor={true}
      headerWhiteBg={true}
      requestButtonBlue={true}
      // ogImage={sourceUrl}
    >
      {(commonData) => (
        <>
          <div className="scr-ocx-gighlights">
            <div className="scr-wrap">
              <Video />
              <LetsTalkFormContainer customData={formData} />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Highlights;
